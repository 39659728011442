import {
  ComponentBookingOptionsOffSite,
  ComponentElementsListItemWithIcon,
  Maybe,
  Product,
} from '@/__generated__/schema.graphql.types';
import { PropsWithChildren } from 'react';
import styles from 'styles/MaterialIcons.module.scss';
import Markdown from 'components/elements/Markdown';

import Link from 'next/link';
import { cn } from '@/lib/utils';
import { Separator } from 'components/ui/separator';
import { Button } from 'components/ui/button';

const getOffsitePrice = (
  product: Product,
  possibleFootnote?: Maybe<string> | string,
): string => {
  let value = '';

  const hasFootnote = (possibleFootnote?.length ?? 0) > 0;
  const hasPrice =
    typeof product.price_offsite !== 'undefined' &&
    product.price_offsite !== null;
  const hasOnsiteUnit = (product.unit_offsite?.length ?? 0) > 0;

  if (hasOnsiteUnit) {
    value = hasPrice
      ? `al. ${product.price_offsite}€ / ${product.unit_offsite}`
      : '-';
  } else {
    value = hasPrice ? `al. ${product.price_offsite}€` : '-';
  }

  return `${value}${hasFootnote ? '†' : ''}`;
};

const Heading = ({ children }: PropsWithChildren) => {
  return (
    <h3 className="text-green-900 text-[1.5rem] font-semibold leading-8 text-center">
      {children}
    </h3>
  );
};

const Reason = ({ icon, title, body }: ComponentElementsListItemWithIcon) => {
  return (
    <div className="grid grid-cols-1 grid-rows-[min-content_auto] lg:grid-cols-[min-content_auto] lg:auto-rows-auto gap-x-8">
      <span
        className={cn(
          styles.materialIcons,
          `relative col-span-1 row-span-2 mb-4 lg:mb-0 mx-auto lg:mx-0`,
          'after:text-green-300',
          'before:bg-green-100 rounded-xl overflow-hidden',
        )}
        style={{
          // @ts-expect-error variables are unknown at compile time
          '--size': '1.5rem',
        }}
      />
      <style jsx>{`
        span {
          padding: calc(var(--size) + 0.5rem);
        }
        span:after {
          content: '${icon}';
          position: absolute;
          display: flex;
          justify-content: center;
          align-items: center;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
        }
        span:before {
          content: '';
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
        }
      `}</style>
      <h4 className="text-green-900 font-semibold leading-6 text-[1rem] text-center lg:text-left">
        {title}
      </h4>
      <Markdown
        content={body ?? ''}
        className="text-green-900 leading-7 font-normal text-center lg:text-left"
      />
    </div>
  );
};

const Footnote = ({
  pricing_footnote,
}: {
  pricing_footnote: ComponentBookingOptionsOffSite['pricing_footnote'];
}) => (
  <div>
    {pricing_footnote ? (
      <span className="text-green-700 text-[0.875rem] font-medium leading-6">
        † {pricing_footnote}
      </span>
    ) : null}
  </div>
);

export const BookingOptionOffsite = ({
  associatedProduct,
  pricing_footnote,
  heading,
  reasons,
  link,
}: ComponentBookingOptionsOffSite & { associatedProduct: Product }) => {
  const Reasons = reasons
    ?.filter((reason) => reason !== null && typeof reason !== 'undefined')
    .map((reason) => <Reason key={reason.id} {...reason} />);

  const price = getOffsitePrice(associatedProduct, pricing_footnote);

  return (
    <article className="flex flex-col gap-y-12 bg-gradient-to-b from-[rgba(34,197,94,0.05)] to-[rgba(34,197,94,0.03)] p-8 pt-12 rounded-2xl selection:bg-green-500">
      <Heading>{heading}</Heading>
      {Reasons}
      <Separator className="bg-green-700 opacity-25" />
      <div className="flex flex-col text-center gap-y-6">
        <div className="flex flex-col gap-y-2">
          <p className="text-green-900 font-semibold text-[1rem] leading-6">
            {price}
          </p>
          <span className="text-green-900 uppercase text-[0.75rem] font-semibold leading-5">
            Väljasõit soovitud asukohta
          </span>
        </div>
        <div>
          <Button
            asChild
            className="bg-green-500 hover:bg-green-600 font-semibold"
          >
            <Link href={link?.url ?? '#'}>{link?.label ?? ''}</Link>
          </Button>
        </div>
        <Footnote pricing_footnote={pricing_footnote} />
      </div>
    </article>
  );
};
