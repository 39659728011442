'use client';
import StrapiImage from 'components/shared/Image';
import Markdown from 'components/elements/Markdown';

import { FC, ReactNode } from 'react';
import {
  ComponentSectionsTrainingsHero,
  Product,
} from '__generated__/schema.graphql.types';
import { Bus, LucideProps, School, Timer, UserCheck, Users } from 'lucide-react';
import { InfoBlock } from 'components/shared/InfoBlock';
import { Separator } from '@/components/ui/separator';
import { Button } from 'components/ui/button';
import Link from 'next/link';

interface HeaderProps {
  data: ComponentSectionsTrainingsHero;
  pageAccentColor?: string;
  associatedProduct: Product;
}

const InfoBlocks = ({ product }: { product: Product }) => {
  return (
    <>
      {product?.duration ? (
        <InfoBlock>
          <InfoBlock.Icon>
            <Timer />
          </InfoBlock.Icon>
          <InfoBlock.Content>
            <InfoBlock.Label>{product.duration}</InfoBlock.Label>
          </InfoBlock.Content>
          <InfoBlock.Content>
            <InfoBlock.Description className="uppercase">
              Kestus
            </InfoBlock.Description>
          </InfoBlock.Content>
        </InfoBlock>
      ) : null}
      {product?.players ? (
        <InfoBlock>
          <InfoBlock.Icon>
            <Users />
          </InfoBlock.Icon>
          <InfoBlock.Content>
            <InfoBlock.Label>{product.players}</InfoBlock.Label>
          </InfoBlock.Content>
          <InfoBlock.Content>
            <InfoBlock.Description className="uppercase">
              Osalejat
            </InfoBlock.Description>
          </InfoBlock.Content>
        </InfoBlock>
      ) : null}
      {product?.ageRange ? (
        <InfoBlock>
          <InfoBlock.Icon>
            <UserCheck />
          </InfoBlock.Icon>
          <InfoBlock.Content>
            <InfoBlock.Label>{product.ageRange}</InfoBlock.Label>
          </InfoBlock.Content>
          <InfoBlock.Content>
            <InfoBlock.Description className="uppercase">
              Soovitatud vanus
            </InfoBlock.Description>
          </InfoBlock.Content>
        </InfoBlock>
      ) : null}
    </>
  );
};

type PriceCardProps = {
  Icon: (props: LucideProps) => ReactNode;
  price: number | string;
  label: string
}

const PriceCard = ({ Icon, price, label }: PriceCardProps) => {
  return (
    <div className="grid grid-cols-[auto_auto] justify-items-start items-center gap-y-5 gap-x-4">
      <div className='bg-orange-100 size-14 flex justify-center items-center rounded-xl'>
        <Icon className='text-amber-700'/>
      </div>
      <div className="flex flex-col text-left gap-y-1">
        <span className="font-semibold text-amber-900 text-[1rem] leading-6 uppercase">
          al. {price}€
        </span>
        <span className="uppercase font-semibold text-amber-600 text-[0.75rem] leading-5">
          {label}
        </span>
      </div>
    </div>
  )
}

const TrainingsHero: FC<HeaderProps> = ({
  data,
  associatedProduct,
}) => {
  const { heading, kicker, description, images } = data;

  return (
    <div className='bg-gradient-to-br from-[#FFE9CC19] to-[#FFE9CC7F]'>
      <section className="container py-32 pt-24 lg:pt-48 grid grid-cols-1 [grid-template-areas:'aside''main'] lg:[grid-template-areas:'aside_main'] lg:grid-cols-[1fr_1fr] gap-x-16 gap-y-12">
        <div className="space-y-12 [grid-area:main]">
          <div className="col-span-1">
            <div className="gap-y-1 mb-12">
              <h1 className="text-[3rem] sm:text-[2.5rem] break-all md:text-[3rem] my-0 text-amber-900 leading-[1.5] font-bold font-serif tracking-normal">
                {heading}
              </h1>
              <span className='font-semibold uppercase text-[0.875rem] text-amber-900'>
                {kicker}
              </span>
            </div>
            <div className="flex flex-row flex-wrap gap-x-6 md:gap-x-12 gap-y-8 mb-12">
              <InfoBlocks product={associatedProduct} />
            </div>
            <Markdown
              content={description ?? ''}
              className="text-amber-900 max-w-2xl lg:max-w-lg leading-7 text-[1rem] space-y-2"
            />
          </div>
          <div className="flex  gap-x-4 gap-y-4">
            <PriceCard Icon={School} label='Grupile linnakus' price={associatedProduct.price_onsite ?? ''} />
            <PriceCard Icon={Bus} label='Väljasõit Eestis' price={associatedProduct.price_offsite ?? ''} />
          </div>
          <div>
            <Button
              className="justify-self-stretch bg-amber-500 text-white hover:bg-amber-600"
              asChild
            >
              <Link href="#broneeri">Broneeri linnakus</Link>
            </Button>
            <Button
              variant="link"
              className="justify-self-stretch text-amber-900"
              asChild
            >
              <Link href="/kontakt">Võta ühendust</Link>
            </Button>
          </div>
        </div>
        <aside className="[grid-area:aside]">
          <div className="grid grid-cols-2 grid-flow-col h-96 lg:h-full gap-6">
            <div className="col-span-1 pt-16">
              <div className='relative h-full w-full overflow-hidden rounded-xl rounded-l-[4rem] bg-slate-100 [box-shadow:_0px_100px_80px_rgba(120,_53,_15,_0.07),_0px_41.7776px_33.4221px_rgba(120,_53,_15,_0.0503198),_0px_22.3363px_17.869px_rgba(120,_53,_15,_0.0417275),_0px_12.5216px_10.0172px_rgba(120,_53,_15,_0.035),_0px_6.6501px_5.32008px_rgba(120,_53,_15,_0.0282725),_0px_2.76726px_2.21381px_rgba(120,_53,_15,_0.0196802)]'>
                {images.data?.[0] ? (
                  <StrapiImage
                    fill
                    style={{ objectFit: 'cover' }}
                    strapi={images.data[0]}
                  />
                ) : null}
              </div>
            </div>
            <div className="col-span-1 pb-16">
              <div className='relative h-full w-full overflow-hidden rounded-xl rounded-r-[4rem] bg-slate-100 [box-shadow:_0px_100px_80px_rgba(120,_53,_15,_0.07),_0px_41.7776px_33.4221px_rgba(120,_53,_15,_0.0503198),_0px_22.3363px_17.869px_rgba(120,_53,_15,_0.0417275),_0px_12.5216px_10.0172px_rgba(120,_53,_15,_0.035),_0px_6.6501px_5.32008px_rgba(120,_53,_15,_0.0282725),_0px_2.76726px_2.21381px_rgba(120,_53,_15,_0.0196802)]'>
                {images.data?.[1] ? (
                  <StrapiImage
                    fill
                    style={{ objectFit: 'cover' }}
                    strapi={images.data[1]}
                  />
                ) : null}
              </div>
            </div>
          </div>
        </aside>
      </section>
    </div>
  );
};

export default TrainingsHero;
