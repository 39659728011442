'use client';
import Markdown from 'components/elements/Markdown';

import { FC } from 'react';
import {
  ComponentSectionsBookingOptions,
  Product,
} from '__generated__/schema.graphql.types';
import { BookingOptionOnsite } from './booking-options/Onsite';
import { BookingOptionOffsite } from './booking-options/Offsite';
import { BookingOptionSelfService } from './booking-options/SelfService';

interface BookingOptionsProps {
  data: ComponentSectionsBookingOptions;
  pageAccentColor?: string;
  associatedProduct: Product;
}

export const BookingOptions: FC<BookingOptionsProps> = ({
  data,
  associatedProduct,
}) => {
  const {
    heading,
    body,
    booking_on_site,
    booking_off_site,
    booking_self_service,
  } = data;

  return (
    <div className="bg-gradient-to-b from-neutral-50 to-white">
      <div
        className="container py-16 lg:py-32 lg:pt-24 grid grid-cols-[1fr_1fr] gap-x-8 gap-y-12"
        id="broneeri"
      >
        <div className="flex flex-col items-center col-span-2 mb-8 text-center">
          <h2 className="text-[2.25rem] my-0 text-slate-700 leading-[1.5] font-semibold mb-6 font-serif">
            {heading}
          </h2>
          <Markdown content={body ?? ''} className="text-slate-500 max-w-2xl" />
        </div>
        <div className="grid grid-cols-[repeat(auto-fit,26.5rem)] gap-x-8 gap-y-8 col-span-2 justify-center items-start">
          {booking_on_site ? (
            <BookingOptionOnsite
              associatedProduct={associatedProduct}
              {...booking_on_site}
            />
          ) : null}
          {booking_off_site ? (
            <BookingOptionOffsite
              associatedProduct={associatedProduct}
              {...booking_off_site}
            />
          ) : null}
          {booking_self_service ? (
            <BookingOptionSelfService
              associatedProduct={associatedProduct}
              {...booking_self_service}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};
